import { BuyInHandBox } from './BuyInHandBox.jsx'
import { Button } from './Button.jsx'
import { ref } from 'vue'


const selectedProduct = ref(null)

function updateSelectedProduct(product) {
  selectedProduct.value = product
}
/**
 * 顺手购插件
 * @param {Object} data - 配置数据
 * @param {Object} data.language - 多语言
 * @param {boolean} data.complianceMode - 是否合规模式
 * @param {Object} data.product_bff_depend_data - 商卡的前置依赖数据
 */
export const buyInHandPlugin = (data) => {
  let pluginContext = null
  let analysisConfig = null
  return {
    name: 'BuyInHand',
    beforeOpen: (data) => {
      analysisConfig = data?.op?.analysisConfig
      return false
    },
    register: (context) => {
      pluginContext = context
    },
    renderSubZone: (context) => {
      const { isMainProduct, productInfo, skuInfo, mallCode, isSupportOneClickPay } = context
      const isCustomization = productInfo?.customization_attributes?.customization_flag === 1
      // 主商品
      // 非命中礼品购
      // 非预售商品
      // 非skc售罄
      // 非定制商品
      // 非一键购商品
      if(isMainProduct && !productInfo?.giftGoodsSelectId && !productInfo?.__isNewProductUnSale && Number(productInfo?.stock) > 0 && !isCustomization && !isSupportOneClickPay) {
        return BuyInHandBox({
          language: data?.language,
          complianceMode: data?.complianceMode,
          useModules: pluginContext?.useModules,
          skuInfo: skuInfo,
          mallCode: mallCode,
          productCardDeps: data?.product_bff_depend_data,
          selectedProduct: selectedProduct.value,
          updateSelectedProduct
        })
      }
    },
    renderAddCartButton: (context) => {
      const { isCustomization, isSoldOut, unListed, mallStock, isSupportOneClickPay } = context
      // 勾选附属商品
      // 非预售商品
      // 非售罄
      // 非定制商品
      // 非一键购商品
      if(selectedProduct.value && !unListed && !(isSoldOut || mallStock == 0)  && !isCustomization && !isSupportOneClickPay) {
        return Button({
          text: data?.language?.SHEIN_KEY_PWA_37500 || 'Add Together',
          addCartClick: context.addCartClick,
          selectedProduct: selectedProduct.value,
        })
      }
    },
    callbacks: {
      onAddCartFinished(data) {
        // 加车成功后，如果有选中附属商品
        if(data?.isSuccess && selectedProduct?.value) {
          const quickShipSkuList = selectedProduct.value.multiLevelSaleAttribute?.sku_list?.filter(sku => sku.isSupportQuickShip === '1')
          const quickShip = quickShipSkuList?.length ? selectedProduct.value.quickship === '1' ? 1 : 3 : 0
          daEventCenter.triggerNotice({
            daId: '1-8-1-1',
            extraData: {
              from: 'buy_in_hand',
              result: { 
                code: data?.addCartResponse?.info?.additionalProductsCode,
                msg: data?.addCartResponse?.info?.additionalProductsCode === '0' ? null : data?.addCartResponse?.info?.additionalProductsErrorMsg, 
              },
              postData: {
                sku_code: selectedProduct.value.sku_code,
                goods_id: selectedProduct.value.goods_id,
                mall_code: selectedProduct.value.mall_code,
                sku_id: selectedProduct.value.goods_sn,
                quickship_tp: quickShip,
                location: analysisConfig?.sa?.location || 'popup',
              },
              button_type: 'buy_in_hand'
            }
          })
        }
      }
    }
  }
}
